// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// HOME
@import 'home';

// BATTERIES
@import 'batteries';

// SOLE
@import 'sole';

*{
    scroll-behavior: smooth;
}

.hidden {
    display: none;
}
